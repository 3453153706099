<template>
  <div>
    <!-- 轮播 -->
    <div>
      <van-swipe :autoplay="3000" indicator-color="white">
        <van-swipe-item>
          <div @click="goLink('http://bmou.cn/')">
            <img
              class="homeCarousel_img"
              src="@/assets/img/home/banner-home.jpg"
            />
          </div>
        </van-swipe-item>
        <van-swipe-item>
          <div
            @click="
              goLink(
                'https://shop389691363.taobao.com/?spm=a230r.7195193.1997079397.2.77371f40wd5lS2'
              )
            "
          >
            <img
              class="homeCarousel_img"
              src="@/assets/img/home/banner-Mobile.png"
            />
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 软件介绍 -->
    <div class="homeSoftware">
      <div class="homeSoftware_box">
        <div class="homeSoftware_title">冰眸食物视觉识别管理软件</div>
        <div class="homeSoftware_up">
          <div class="homeSoftware_content" style="left: 26px">
            <div class="homeSoftware_intLetf">
              <div>
                <div class="homeSoftware_intText">根据天气推荐心情菜谱</div>
              </div>
              <div>
                <img
                  class="homeSoftware_intIcon"
                  src="@/assets/img/home/imgPic.png"
                />
              </div>
            </div>
            <div class="homeSoftware_intLetf">
              <div>
                <div class="homeSoftware_intText">
                  冰箱管理、成员设置简单易用
                </div>
                <div class="homeSoftware_intInfo">
                  轻松设置冰箱分区管理，个人资料信息，消息中心、提供送货地址信息、菜谱发表及操作、记录自己的行程及影像等一应俱全
                </div>
              </div>
              <div>
                <img
                  class="homeSoftware_intIcon"
                  src="@/assets/img/home/ic_rjsbie_tq.png"
                />
              </div>
            </div>
            <div class="homeSoftware_intLetf">
              <div>
                <div class="homeSoftware_intText">
                  参考食物制作、分享烹饪心得
                </div>
                <div class="homeSoftware_intInfo">
                  查看各种各样食物制作的方法，让冰箱食物的制作不再是一件难事。也可以把自己食物烹饪技术及方法分享给他人
                </div>
              </div>
              <div>
                <img
                  class="homeSoftware_intIcon"
                  src="@/assets/img/home/ic_rjsbie_cp.png"
                />
              </div>
            </div>
          </div>
          <div class="homeSoftware_intImg" style="right: -44px">
            <img src="@/assets/img/home/pic_rjsbie_yjs.png" />
          </div>
        </div>
        <div class="homeSoftware_down">
          <div class="homeSoftware_intImg" style="left: -44px">
            <img src="@/assets/img/home/pic_rjsbie_yjs.png" />
          </div>
          <div class="homeSoftware_content" style="right: 26px">
            <div class="homeSoftware_intRight" style="margin-top: 46px">
              <div>
                <img
                  class="homeSoftware_intIcon"
                  src="@/assets/img/home/ic_rjsbie_sj.png"
                />
              </div>
              <div>
                <div class="homeSoftware_intText">冰箱食物进出时间管理</div>
                <div class="homeSoftware_intInfo">
                  提供当前天气状况，可以进行保质提醒、食物分类以及自定义冰箱的存藏区域管理，清楚知道冰箱食物的进出时间记录，让食物保质管理成为可能
                </div>
              </div>
            </div>
            <div
              class="homeSoftware_intRight"
              style="margin-top: 40px; margin-bottom: 24px"
            >
              <div>
                <img
                  class="homeSoftware_intIcon"
                  src="@/assets/img/home/ic_rjsbie_gw.png"
                />
              </div>
              <div>
                <div class="homeSoftware_intText">冰箱食物进出时间管理</div>
                <div class="homeSoftware_intInfo">
                  根据需求定位查看附近食材及门店情况，清楚知道当时时令推荐使用的食材信息，根据APP上显示的食材存量及时补充食物，享受购物便捷的服务
                </div>
              </div>
            </div>
            <div class="homeSoftware_btn">
              <div @click="goRouter('/AboutBingmoMobile')">了解更多</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 使用场景 -->
    <div class="homeScenes">
      <div class="homeScenes_title">
        <div>使用场景</div>
        <div>usage scenario</div>
      </div>
      <div class="homeScenes_list">
        <div
          v-for="(item, index) in scenesList"
          :key="index"
          class="homeScenes_item"
          @click="handleSelectChange(index)"
          :style="scenesID == index ? '' : 'height: 100px;'"
        >
          <div class="homeScenes_img">
            <img :src="item.imgUrl" />
          </div>
          <div class="homeScenes_itemTitle">
            <div>{{ item.title }}</div>
            <div>{{ item.subtitle }}</div>
          </div>
          <div
            class="homeScenes_info"
            :style="scenesID == index ? '' : 'bottom: -100px;'"
          >
            {{ item.info }}
          </div>
        </div>
      </div>
    </div>
    <!-- 使用方式 -->
    <div class="homeVideo">
      <div class="homeScenes_title">
        <div>使用方式</div>
        <div>Method of use</div>
      </div>
      <video autoplay controls loop src="@/assets/img/home/BmuApp2.mp4"></video>
    </div>
    <!-- 联系 -->
    <!-- <div class="homeContact">
      <div class="homeContact_text">
        <strong>留下您的联系方式</strong>
        <strong>将有可能免费获得冰眸</strong>
      </div>
      <div>
        <input type="text" placeholder="姓名" />
        <input type="text" placeholder="邮箱" />
      </div>
      <div>
        <div class="homeContact_btn">立刻申请免费</div>
      </div>
    </div> -->
    <!-- APP下载 -->
    <div class="homeApp">
      <div class="homeApp_bg">
        <img src="@/assets/img/home/bg_download2.jpg" />
      </div>
      <div class="homeApp_content">
        <!-- 透明背景板 -->
        <div class="homeApp_bgTran"></div>
        <!-- 背景板内容 -->
        <div class="homeApp_box">
          <div class="homeApp_boxTitle">
            <div>立即获取新的移动应用程序</div>
            <div>Get the new mobile apps today</div>
          </div>
          <div class="homeApp_boxBtn">
            <a
              href="https://vlimsoft-test.oss-cn-shenzhen.aliyuncs.com/apk/iceEyes1.0.1.apk"
            >
              <div>
                <i class="iconfont iconanzhuo"></i>
              </div>
              <div class="homeApp_boxBtn_info">
                <div>Available on the</div>
                <div>App Market</div>
              </div>
            </a>
            <!-- <a>
              <div>
                <i class="iconfont iconpingguo"></i>
              </div>
              <div class="homeApp_boxBtn_info">
                <div>Available on the</div>
                <div>App Stroe</div>
              </div>
            </a> -->
          </div>
        </div>
      </div>
    </div>
    <!-- 最新动态 -->
    <div class="homeDynamic">
      <div class="homeScenes_title">
        <div>最新动态</div>
        <div>RECENT NEWS</div>
      </div>
      <div class="homeDynamic_box">
        <!-- 主资讯 -->
        <div
          @click="goNewsDetails('/NewsDetailsMobile', item)"
          class="homeDynamic_main"
          v-for="(item, index) in oneListMain"
          :key="index"
        >
          <!-- 图片 -->
          <div class="homeDynamic_mainImg">
            <img :src="item.imgUrl" />
          </div>
          <!-- 内容 -->
          <div class="homeDynamic_mainInfo">
            <div class="homeDynamic_title">
              {{ item.title }}
            </div>
            <div class="homeDynamic_info">
              {{ item.info }}
            </div>
            <div class="homeDynamic_time">{{ !item.time ? '' : item.time.split(' ')[0] }}</div>
          </div>
        </div>
        <!-- 列表 -->
        <div class="homeDynamic_list">
          <!-- 列表项 -->
          <div
            @click="goNewsDetails('/NewsDetailsMobile', item)"
            v-for="(item, index) in oneList"
            :key="index"
            class="homeDynamic_listItem"
          >
            <div class="homeDynamic_listText">
              <div>· </div>
              <div> {{ item.title }}</div>
            </div>
            <div class="homeDynamic_listTime">{{ !item.time ? '' : item.time.split(' ')[0] }}</div>
          </div>
        </div>
      </div>
      <div class="homeDynamic_btn">
        <div @click="goRouter('/NewsMobile')">更多资讯</div>
      </div>
    </div>
    <!-- 友情链接 -->
    <div class="homeLink">
      <div>
        <div class="homeScenes_title">
          <div>友情链接</div>
          <div>friendly link</div>
        </div>
        <div class="homeLink_link">
          <div @click="goLink('http://www.vlimsoft.com/')">
            <img src="@/assets/img/home/link-vlimsoft.png" />
          </div>
          <div
            @click="
              goLink(
                'https://shop389691363.taobao.com/?spm=a230r.7195193.1997079397.2.77371f40wd5lS2'
              )
            "
          >
            <img src="@/assets/img/home/link-tbdp.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 使用场景ID
      scenesID: 0,
      // 使用场景列表
      scenesList: [
        {
          imgUrl: require("@/assets/img/home/bg_wctzhong.png"),
          title: "外出路途当中",
          subtitle: "掌握冰箱食物",
          info: "外出路途中，实时查看家庭成员使用食物的情况，冰眸会及时将逾期食物的信息推送至app",
        },
        {
          imgUrl: require("@/assets/img/home/bg_gzkyu.png"),
          title: "工作空余时间",
          subtitle: "充分利用时间碎片",
          info: "查看冰箱食物种类信息，借鉴app的食物制作方法，制定午餐、晚餐的菜单，不再为吃什么纠结",
        },
        {
          imgUrl: require("@/assets/img/home/bg_elzyu.png"),
          title: "休闲娱乐之余",
          subtitle: "休闲之余处理食物",
          info: "及时处理好食物的保质，不造成食物的人为浪费，休闲之余可以及时管理食物",
        },
      ],
      // 行业资讯主数据列表
      oneList: [
        {
          imgUrl: require("@/assets/img/home/Bingmo online promotion poster cover.png"),
          title: "2021年8月18日“冰眸app”正式上线。",
          time: "2021-08-18 09:00",
          content: `
        <div class="Section0" style="layout-grid:15.6000pt;">
    <p class=MsoNormal><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
          <font face="宋体">2021年8月18日“冰眸app”正式上线</font>
        </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
          <font face="宋体">，</font>
        </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
          <font face="宋体">运营体系开发完成；</font>
        </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
          <o:p></o:p>
        </span></b></p>
    <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;mso-font-kerning:1.0000pt;
    background:rgb(255,255,255);mso-shading:rgb(255,255,255);">
        <font face="宋体">近日，</font>“冰眸app”已完成升级并正式上线，冰眸app
      </span><span style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;mso-font-kerning:1.0000pt;
    background:rgb(255,255,255);mso-shading:rgb(255,255,255);">
        <font face="宋体">将在各大应用市场陆续开发用户端下载</font>
      </span><span style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;mso-font-kerning:1.0000pt;
    background:rgb(255,255,255);mso-shading:rgb(255,255,255);">
        <font face="宋体">。</font>
      </span><span style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;mso-font-kerning:1.0000pt;
    background:rgb(255,255,255);mso-shading:rgb(255,255,255);">
        <font face="宋体">通过该应用用户可以对冰箱食物管理，实现</font>“掌握食物库存
      </span><span style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;mso-font-kerning:1.0000pt;
    background:rgb(255,255,255);mso-shading:rgb(255,255,255);">
        <font face="宋体">，</font>
      </span><span style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;mso-font-kerning:1.0000pt;
    background:rgb(255,255,255);mso-shading:rgb(255,255,255);">&nbsp;<font face="宋体">确保食物新鲜</font></span><span style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;mso-font-kerning:1.0000pt;
    background:rgb(255,255,255);mso-shading:rgb(255,255,255);">
        <font face="宋体">，</font>
      </span><span style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;mso-font-kerning:1.0000pt;
    background:rgb(255,255,255);mso-shading:rgb(255,255,255);">&nbsp;<font face="宋体">守护家人健康</font>”这一目的，冰眸</span><span
        style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;mso-font-kerning:1.0000pt;
    background:rgb(255,255,255);mso-shading:rgb(255,255,255);">
        <font face="宋体">—</font>
      </span><span style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;mso-font-kerning:1.0000pt;
    background:rgb(255,255,255);mso-shading:rgb(255,255,255);">
        <font face="宋体">眸世代</font>&nbsp;鲜百味！
      </span><span style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;mso-font-kerning:1.0000pt;
    background:rgb(255,255,255);mso-shading:rgb(255,255,255);">
        <o:p></o:p>
      </span></p>
    <p class=MsoNormal align=center style="text-align:center;"><img width="378" height="671"
        src="${require("@/assets/img/News/A product of Guangzhou Weilin Software Co., Ltd..jpg")}"><span style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;mso-font-kerning:1.0000pt;
    background:rgb(255,255,255);mso-shading:rgb(255,255,255);">
        <o:p>&nbsp;</o:p>
      </span></p>
    <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
    mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
        <o:p>&nbsp;</o:p>
      </span></p>
  </div>
        `,
          info: "近日，“冰眸app”已完成升级并正式上线，冰眸app将在各大应用市场陆续开发用户端下载。通过该应用用户可以对冰箱食物管理，实现“掌握食物库存， 确保食物新鲜， 守护家人健康”这一目的，冰眸—眸世代 鲜百味！",
        },
        {
          imgUrl: require("@/assets/img/home/Founding Poster-Cover.png"),
          title: "2021年7月2日",
          time: "2021-07-02 09:00",
          content: `
        <div class="Section0" style="layout-grid:15.6000pt;">
    <p class=MsoNormal><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
          <font face="宋体">2021年7月2日</font>
        </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
          <o:p></o:p>
        </span></b></p>
    <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:'Microsoft YaHei UI'; 
    letter-spacing:0.4000pt;text-transform:none;font-style:normal;
    font-size:12.5000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
    mso-shading:rgb(255,255,255);">
        <font face="Microsoft YaHei UI">掌握食物库存，确保食物新鲜，守护家人健康！</font>
      </span><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
          <o:p></o:p>
        </span></b></p>
    <p class=MsoNormal align=center style="text-align:center;"><img width="356" height="632"
        src="${require("@/assets/img/News/Guangzhou Bingmo Electronics Co., Ltd. was established.jpg")}"><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
          <o:p>&nbsp;</o:p>
        </span></b></p>
    <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
    mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
        <o:p>&nbsp;</o:p>
      </span></p>
  </div>
        `,
          info: "掌握食物库存，确保食物新鲜，守护家人健康！",
        },
        {
          imgUrl: require("@/assets/img/News/Third edition on June 30, 2021.jpg"),
          title:
            "2021年6月30日第三版“冰眸”APP及小程序开发完成，用户体验测试开始；",
          time: "2021-06-30 09:00",
          content: `
        <div class="Section0" style="layout-grid:15.6000pt;">
    <p class=MsoNormal><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
          <font face="宋体">2021年6月30日第三版“冰眸”APP及小程序开发完成，用户体验测试开始；</font>
        </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
          <o:p></o:p>
        </span></b></p>
    <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
        <font face="宋体">微林冰眸小程序新版正式更新，</font>
      </span><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
        <font face="宋体">欢迎大家免费体验！冰眸</font>—冰箱食物管家！
      </span><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
        <o:p></o:p>
      </span></p>
    <p class=MsoNormal align=center style="text-align:center;"><img    
        src="${require("@/assets/img/News/Third edition on June 30, 2021.jpg")}"><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
        <o:p>&nbsp;</o:p>
      </span></p>
    <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:12.0000pt;mso-font-kerning:1.0000pt;">
        <o:p>&nbsp;</o:p>
      </span></p>
    <p class=MsoNormal><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
          <o:p>&nbsp;</o:p>
        </span></b></p>
    <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
    mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
        <o:p>&nbsp;</o:p>
      </span></p>
  </div>
        `,
          info: "微林冰眸小程序新版正式更新，欢迎大家免费体验！冰眸—冰箱食物管家！",
        },
      ],
      oneListMain: [
        {
          imgUrl: require("@/assets/img/home/Warm congratulations on the establishment of Guangzhou Weilin Co., Ltd. 220.png"),
        //   title: "2021年12月8日热烈祝贺广州微林股份有限公司成立！",
        //   time: "2021-12-08 09:00",
        //   content: `
        // <div class="Section0"  style="layout-grid:15.6000pt;" ><p class=MsoNormal  style="text-align:left;" ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
        // font-size:18.0000pt;mso-font-kerning:1.0000pt;" ><font face="宋体" >热烈祝贺广州微林股份有限公司成立！</font></span></b><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
        // mso-font-kerning:1.0000pt;" ><br></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
        // mso-font-kerning:1.0000pt;" >&nbsp;2021年12月8日广州微林股份有限公司宣告正式成立！</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
        // mso-font-kerning:1.0000pt;" ><br></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
        // mso-font-kerning:1.0000pt;" ><font face="宋体" >自</font>2017年微林软件成立以来，历经五年的五年的稳步发展，从专注于视觉检测和视觉工业自动化的企业，逐步向智能化食物管理的的新方向转变,致力于让中国4.6亿的家庭的冰箱实现智能化食物管理，并通过生鲜购物和线上烹饪直播等为家庭提供亲切关怀服务。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
        // mso-font-kerning:1.0000pt;" ><br></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
        // mso-font-kerning:1.0000pt;" >&nbsp;广州微林股份有限公司的成立意味着向“2025年，成为视觉领域的知名企业”这一伟大愿景迈进了重大的一步。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
        // mso-font-kerning:1.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="text-align:left;" ><img width="303"  height="538"  src="${require("@/assets/img/home/Warm congratulations on the establishment of Guangzhou Weilin Co., Ltd. 220.png")}" ><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(12,12,12);
        // letter-spacing:0.0000pt;text-transform:none;font-style:normal;
        // font-size:13.5000pt;mso-font-kerning:1.0000pt;" ><o:p>&nbsp;</o:p></span></p></div>
        // `,
        //   info: "2021年12月8日广州微林股份有限公司宣告正式成立！自2017年微林软件成立以来，历经五年的五年的稳步发展，从专注于视觉检测和视觉工业自动化的企业，逐步向智能化食物管理的的新方向转变, 致力于让中国4.6亿的家庭的冰箱实现智能化食物管理，并通过生鲜购物和线上烹饪直播等为家庭提供亲切关怀服务。广州微林股份有限公司的成立意味着向“2025年，成为视觉领域的知名企业”这一伟大愿景迈进了重大的一步。",
        },
      ],
    };
  },
  methods: {
    // 处理使用场景点击选择事件
    handleSelectChange(index) {
      this.scenesID = index;
    },
    // 跳转外网
    goLink(url) {
      window.open(url, "_blank");
    },
    // 跳转路由
    goRouter(url) {
      this.$router.push({
        path: url,
      });
    },
    // 跳转详情
    goNewsDetails(url, item) {
      this.$router.push({
        path: url,
        query: {
          title: item.title,
          time: item.time,
          content: item.content,
        },
      });
    },
  },
};
</script>

<style scoped>
/* 轮播图 */
.homeCarousel_img {
  width: 100%;
  height: 180px;
  display: flex;
  object-fit: cover;
}
/* 软件介绍 */
.homeSoftware {
  width: 100%;
  padding: 30px 0 10px;
  box-sizing: border-box;
  background-image: url('../assets/img/home/bg_sjsbie.png');
  background-repeat: no-repeat;
  background-position: center;
}
/* 软件介绍-背景 */
.homeSoftware_bg {
  width: 100%;
  height: 100%;
}
.homeSoftware_bg img {
  width: 100%;
  height: 100%;
  display: flex;
  object-fit: cover;
}
/* 软件介绍-内容 */
.homeSoftware_box {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.homeSoftware_title {
  width: 100%;
  box-sizing: border-box;
  color: #fff;
  font-size: 24px;
  text-align: center;
  margin-bottom: 24px;
}
.homeSoftware_up {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.homeSoftware_down {
  position: relative;
  top: -30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.homeSoftware_content {
  position: relative;
}
.homeSoftware_intIcon {
  width: 20px;
  height: 20px;
  display: flex;
  object-fit: cover;
}
.homeSoftware_intImg {
  position: relative;
}
.homeSoftware_intImg img {
  width: 172px;
  height: 370px;
  display: flex;
  object-fit: cover;
}
.homeSoftware_intLetf {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
}
.homeSoftware_intRight {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 30px;
}
.homeSoftware_intLetf > div:nth-of-type(1) {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-direction: column;
  margin-right: 6px;
}
.homeSoftware_intRight > div:nth-of-type(2) {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-left: 6px;
}
.homeSoftware_intText {
  color: #fff;
  font-size: 13px;
  margin-bottom: 4px;
}
.homeSoftware_intInfo {
  padding-top: 4px;
  color: #f5f5f5;
  font-size: 12px;
  border-top: 1px solid #fff;
}
.homeSoftware_btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.homeSoftware_btn > div {
  color: #fff;
  padding: 8px 28px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #fff;
}
/* 使用场景 */
.homeScenes {
  padding: 40px 0;
}
.homeScenes_title {
  margin-bottom: 30px;
  text-align: center;
}
.homeScenes_title > div:nth-of-type(1) {
  color: #47b0b4;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}
.homeScenes_title > div:nth-of-type(2) {
  color: #47b0b4;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  text-transform: uppercase;
}
/* 使用场景-列表 */
.homeScenes_list {
  padding: 0 12px;
  width: 100%;
  box-sizing: border-box;
}
.homeScenes_item {
  width: 100%;
  height: 244px;
  position: relative;
  transition: all 0.3s;
  overflow: hidden;
}
.homeScenes_img {
  width: 100%;
  height: 100%;
}
.homeScenes_img img {
  width: 100%;
  height: 100%;
  display: flex;
  object-fit: cover;
}
.homeScenes_itemTitle {
  position: absolute;
  top: 20px;
  left: 30px;
}
.homeScenes_itemTitle > div:nth-of-type(1) {
  font-size: 24px;
  font-weight: 500;
  color: #fff;
  line-height: 36px;
}
.homeScenes_itemTitle > div:nth-of-type(2) {
  font-size: 16px;
  color: #fff;
}
.homeScenes_info {
  position: absolute;
  bottom: 30px;
  right: 20px;
  font-size: 14px;
  color: #fff;
  width: 284px;
  height: 42px;
  line-height: 18px;
  transition: all 0.3s;
}
/* 使用方式 */
.homeVideo {
  padding: 40px 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  background-image: url("../assets/img/home/bg_bmouuse.jpg");
  backdrop-filter: blur(10px);
}
.homeVideo video {
  width: 100%;
  display: flex;
  object-fit: cover;
}
/* 联系 */
.homeContact {
  width: 100%;
  height: 350px;
  background: #fff;
  display: flex;
  padding: 40px 48px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
}
.homeContact_text {
  font-size: 20px;
  font-weight: bold;
  color: #333333;
  line-height: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.homeContact > div:nth-of-type(2) {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 40px;
  width: 100%;
}
.homeContact input {
  width: 100%;
  height: 40px;
  outline: none;
  border: 1px solid #47b0b4;
  background: #ffffff;
  font-size: 18px;
  font-weight: 400;
  padding: 10px 12px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
input::-moz-placeholder {
  color: #999999;
}
input::-webkit-input-placeholder {
  color: #999999;
}
input::-ms-input-placeholder {
  color: #999999;
}
.homeContact > div:nth-of-type(3) {
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.homeContact_btn {
  width: 100%;
  height: 40px;
  background: #47b0b4;
  color: #ffffff;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* App */
.homeApp {
  position: relative;
  height: 300px;
}
.homeApp_bg {
  height: 100%;
}
.homeApp_bg img {
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.homeApp_content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 40px 12px;
  box-sizing: border-box;
}
.homeApp_bgTran {
  width: 100%;
  height: 220px;
  background: #000000;
  opacity: 0.5;
  border-radius: 12px;
  backdrop-filter: blur(10px);
}
.homeApp_box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: absolute;
  top: 40px;
  left: 0;
  color: #fff;
  width: 100%;
  height: 100%;
}
.homeApp_boxTitle {
  margin-top: 32px;
}
.homeApp_boxTitle > div:nth-of-type(1) {
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.homeApp_boxTitle > div:nth-of-type(2) {
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}
.homeApp_boxBtn {
  width: 100%;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.homeApp_boxBtn i {
  font-size: 34px;
}
.homeApp_boxBtn > a {
  width: 210px;
  height: 60px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 6px 16px;
  box-sizing: border-box;
  text-decoration: none;
  color: #fff;
}
.homeApp_boxBtn_info > div:nth-of-type(1) {
  font-size: 14px;
  line-height: 21px;
}
.homeApp_boxBtn_info > div:nth-of-type(2) {
  font-size: 20px;
  line-height: 30px;
}
/* 最新动态 */
.homeDynamic {
  padding: 40px 20px;
  width: 100%;
  box-sizing: border-box;
}
.homeDynamic_main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.homeDynamic_mainImg {
  width: calc(45% - 6px);
  height: 112px;
}
.homeDynamic_mainImg img {
  width: 100%;
  height: 100%;
  display: flex;
  object-fit: cover;
}
.homeDynamic_mainInfo {
  width: calc(55% - 6px);
}
.homeDynamic_title {
  color: 333333;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 6px;
}
.homeDynamic_info {
  margin-bottom: 8px;
  color: #B3B3B3;
  font-size: 14px;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.homeDynamic_time {
  color: #CCC;
  font-size: 14px;
  line-height: 18px;
  text-align: right;
}
.homeDynamic_list {
  margin-top: 20px;
}
.homeDynamic_listItem {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.homeDynamic_listText {
  color: #999;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 100px);
}
.homeDynamic_listText > div:nth-of-type(1) {
  margin-right: 5px;
}
.homeDynamic_listText > div:nth-of-type(2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.homeDynamic_listTime {
  color: #ccc;
  font-size: 14px;
}
.homeDynamic_btn {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.homeDynamic_btn > div {
  color: #47B0B4;
  font-size: 16px;
  padding: 10px 42px;
  border: 1px solid #47B0B4;
}
/* 友情链接 */
.homeLink {
  padding: 40px 18px;
  background-color: #f5f5f5;
}
.homeLink_link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.homeLink_link > div {
  width: calc(50% - 10px);
  height: 68px;
  margin-bottom: 20px;
  box-sizing: border-box;
  /* border: 1px solid #b3b3b3; */
}
.homeLink_link img {
  display: flex;
  /* object-fit: cover; */
  width: 100%;
  height: 100%;
}
</style>